import React from "react"
import { Helmet } from "react-helmet"

import { useStaticQuery, graphql } from "gatsby"

import BlogList from "../blocks/Blog/BlogList"
import Layout from "../components/layout"

const BlogPage = () => {
  const { allBlog } = useStaticQuery(
    graphql`
      query {
        allBlog(sort: { order: DESC, fields: published }) {
          totalCount
          nodes {
            title
            slug
            published
            author
            excerpt
            cover {
              src
              alt
            }
            link {
              to
              copy
            }
            tags
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Helmet>
        <title>Paybase - Blog Listing</title>
        <meta property="og:title" content="Paybase - Blog Listing" />
      </Helmet>
      <BlogList data={allBlog.nodes} />
    </Layout>
  )
}

export default BlogPage
